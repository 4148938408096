<template>
    <section>
        <div class="login">
            <div class="container">
                <div class="row">

                    <div class="inn">
                        <div class="lhs">
                            <div class="tit">
                                <h2>Now <b>Find <br> your life partner</b> Easy and fast.</h2>
                            </div>
                            <div class="im">
                                <img src="images/login-couple.png" alt="">
                            </div>
                            <div class="log-bg">&nbsp;</div>
                        </div>
                        <div class="rhs">
                            <div>
                                <div class="form-tit">
                                    <h4>Guard</h4>
                                    <h1>Verify your email id</h1>
                                </div>
                                <div class="form-login">
                                    <form @submit.prevent="verifyCode">
                                        <div class="form-group">
                                            <label class="lb">Email:</label>
                                            <input v-model="email" type="email" class="form-control" id="email"
                                                placeholder="Enter email" name="email" disabled>
                                        </div>  
                                        <div class="form-group">
                                            <label class="lb">code:</label>
                                            <input v-model="code" type="text" class="form-control" id="code"
                                                placeholder="Code" name="code" required>
                                        </div>
                                        <div class="form-tit">
                                            <span style="font-size: 12px; cursor: pointer;" v-on:click="resendCode">Resend Code</span>
                                        </div>
                                        <button type="submit" class="btn btn-primary">Verify</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>

import { mapState } from 'vuex';
import { confirmSignUp, resendSignUpCode } from 'aws-amplify/auth';

export default {
  name: 'VerifyID',
  data() {
    if(!this.$route.query?.email) {
        this.$router.push({"path": "/register"})
    }
    return {
      email: this.$route.query?.email,
      code: '',
    };
  },
  computed: {
    ...mapState(['isUserLoggedIn', 'user', 'admin'])
  },
  methods: {
    async verifyCode() {
        let loader = this.$loading.show();
        try {
            let email_part = this.email.split("@")[0]
            let provider = this.email.split("@")[1]
            let res = await confirmSignUp({ username: `${email_part}:${provider}`, confirmationCode: this.code });
            this.$store.commit("addUser", {
                "username": '',
                "id": res?.userId,
                "email": this.$route.query?.email
              })
            loader.hide();
            this.$router.push({"path": "/user-onboard"})
        } catch (error) {
            console.log(error);
            this.$toast.error("Error Verifing User. Please try again later!")
            loader.hide();
        }
    },

    async resendCode() {
        let loader = this.$loading.show();
        try {

            await resendSignUpCode({ username: this.$route.query?.email });
            loader.hide();
            this.$toast.success("Code sent successfully")
        } catch (error) {
            console.log(error);
            this.$toast.error("Error sending code")
            loader.hide();
        }
    }
  }
//   props: {
//     msg: String
//   }
}
</script>
