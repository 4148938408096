<template>
    <section>
       <div class="login pg-faq">
           <div class="container">
               <div class="row">
                   <div class="inn ab-faq-lhs">
                       <div class="form-tit">
                           <h4>Vanniya Matrimony</h4>
                           <h1>Terms and Conditions</h1>
                       </div>
                       <div id="accordion">
                           <div class="card">
                               <div id="collapse1" class="collapse  show" data-parent="#accordion">
                                   <div class="card-body">
                                       <p>Welcome to Vanniya Matrimony. These Terms and Conditions govern your use of our website and the services provided therein. 
By accessing or using Vanniya Matrimony Website, you agree to comply with and be bound by the following terms and conditions. If you disagree with any part of these terms, please do not use our website.
</p>
                                   </div>
                               </div>
                           </div>
                           <div class="card">
                                <div class="card-header">
                                    <a class="card-link" data-toggle="collapse" href="#collapse2">
                                        Account Registration:
                                    </a>
                                </div>
                                <div id="collapse2" class="collapse show" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>In order to access certain features of the website, you may be required to register an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</p>
                                        <p>You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header">
                                    <a class="card-link" data-toggle="collapse" href="#collapse2">
                                        Eligibility:
                                    </a>
                                </div>
                                <div id="collapse2" class="collapse show" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>You must be at least 18 years old to use Vanniya Matrimony Website and to create a matrimonial profile. By using our services, you represent and warrant that you are of legal age to form a binding contract and meet all eligibility requirements.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header">
                                    <a class="card-link" data-toggle="collapse" href="#collapse2">
                                        User Content:
                                    </a>
                                </div>
                                <div id="collapse2" class="collapse show" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>Users are solely responsible for the content they post on Vanniya Matrimony Website, including profile information, photographs, messages, and other communications.</p>
                                        <p>By posting content on our website, you grant us a non-exclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content throughout the world in any media.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header">
                                    <a class="card-link" data-toggle="collapse" href="#collapse2">
                                        Governing Law:
                                    </a>
                                </div>
                                <div id="collapse2" class="collapse show" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>These Terms and Conditions shall be governed by and construed in accordance with the laws of Andhra Pradesh Jurisdiction, without regarding to its conflict of law principles.</p>
                                        <ul style="margin: 10px;padding: 10px">
                                            <li style="padding:10px">The registration of VM membership and all VM services are deemed to have been entered into within the territorial Jurisdiction of Chittoor, India.</li>
                                            <li style="padding:10px">The VM Members unconditionally agree that all such disputes and / or differences if any shall be governed by the Laws of India and submitting to the exclusive Jurisdiction of appropriate court of law in Chittoor, Andhra Pradesh, India</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header">
                                    <a class="card-link" data-toggle="collapse" href="#collapse2">
                                        Changes to the aggrement:
                                    </a>
                                </div>
                                <div id="collapse2" class="collapse show" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>We reserve the right to modify or replace these Terms and Conditions at any time. Any changes will be effective immediately upon posting on this page. Your continued use of the website after any such changes constitutes your acceptance of the new Terms and Conditions.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header">
                                    <a class="card-link" data-toggle="collapse" href="#collapse2">
                                        Contact Us:
                                    </a>
                                </div>
                                <div id="collapse2" class="collapse show" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>If you have any questions or concerns about these Terms and Conditions, please contact us at vanniyamatrimony@structers.com</p>
                                    </div>
                                </div>
                            </div>
                       </div>
                   </div>
               </div>
           </div>
       </div>
   </section>
</template>

<script>
export default {
 name: 'TermsAndConditions',
}
</script>