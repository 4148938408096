import { AmplifySignUp} from 'aws-amplify/auth';
import { API_ENDPOINT } from "../constants/appConstants";
import { uploadData } from 'aws-amplify/storage';
import { downloadData } from 'aws-amplify/storage';
import axios from 'axios';


export const signUpWithCognito = (user) => {
    return AmplifySignUp({
        username: user.username,
        password: user.password,
        options: {
            userAttributes: {
                email: user.email,
                phone_number: `+91${user.phone}`,
            }
        }
    })
}

export const signUp = (user) => {
    return axios.post(`${API_ENDPOINT}/api/v1/users`, user)
}

export const updateUserBio = async (filename, data) => {
    try {
        const result = await uploadData({
          key: filename,
          data: data,
          options: {
            accessLevel: 'private', // defaults to `guest` but can be 'private' | 'protected' | 'guest' // Optional progress callback.
          }
        }).result;
        console.log('Succeeded: ', result);
      } catch (error) {
        console.log('Error : ', error);
      }
}

export const uploadPhoto = async (filename, data) => {
    try {
        const result = await uploadData({
          key: filename,
          data: data,
          options: {
            accessLevel: 'private', // defaults to `guest` but can be 'private' | 'protected' | 'guest' // Optional progress callback.
          }
        }).result;
        console.log('Succeeded: ', result);
      } catch (error) {
        console.log('Error : ', error);
      }
}


export const getData = async (filename) => {
    try {
        const downloadResult = await downloadData({ key: filename, options: {
            accessLevel: 'private', // defaults to `guest` but can be 'private' | 'protected' | 'guest' // Optional progress callback.
          } }).result;
        const text = await downloadResult.body.text();
        return JSON.parse(text)
    } catch (error) {
        return {}
    }
    
}

export const getImage = async (filename) => {
    try {
        const downloadResult = await downloadData({ key: filename, options: {
            accessLevel: 'private', // defaults to `guest` but can be 'private' | 'protected' | 'guest' // Optional progress callback.
          } }).result;
        return await downloadResult.body.blob();
    } catch (error) {
        return {}
    }
    
}

// Downloads file content to memory


