import { createRouter, createWebHistory } from 'vue-router';
import HomeLayout from './layouts/HomeLayout.vue';
import HomeScreen from './components/Home.vue';
import SignIn from "./components/SignIn.vue";
import SignUp from "@/components/SignUp.vue";
import VerifyID from "@/components/VerifyId.vue";
import BioWelcomeScreen from "@/components/BioWelcomeScreen.vue";
import UserOnboard from "@/components/UserOnboardScreen.vue";
import UserGallery from "@/components/UserGallery.vue";
import UserPreferences from "@/components/UserPreferences.vue";
import AboutUs from "@/components/AboutUs.vue";
import FAQ from "@/components/FAQ.vue";
import TermsAndConditions from "@/components/Terms.vue";
import { authGuard } from './utils/authGuard.js';

const routes = [
  {
    path: '/',
    component: HomeLayout,
    children: [
      {
        path: '',
        component: HomeScreen,
      },
      {
        path: 'signin',
        component: SignIn
      },
      {
        path: 'about-us',
        component: AboutUs
      },
      {
        path: 'terms-conditions',
        component: TermsAndConditions
      },
      {
        path: 'faq',
        component: FAQ
      },
      {
        path: 'register',
        component: SignUp,
      },
      {
        path: 'verify-email',
        component: VerifyID,
      },
      {
        path: 'finish',
        component: BioWelcomeScreen
      },
      {
        path: 'user-onboard',
        component: UserOnboard,
        beforeEnter: authGuard
      },
      {
        path: 'user-gallery',
        component: UserGallery,
        beforeEnter: authGuard
      },
      {
        path: 'user-preferences',
        component: UserPreferences,
        beforeEnter: authGuard
      }
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router