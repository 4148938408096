

<template>
    <div class="pop-bg"></div>
    <!-- <div class="pop-search">
        <span class="ser-clo">+</span>
        <div class="inn">
            <form>
                <input type="text" placeholder="Search here...">
            </form>
            <div class="rel-sear">
                <h4>Top searches:</h4>
                <a href="all-profiles.html">Browse all profiles</a>
                <a href="all-profiles.html">Mens profile</a>
                <a href="all-profiles.html">Female profile</a>
                <a href="all-profiles.html">New profiles</a>
            </div>
        </div>
    </div> -->
    <div class="head-top">
        <div class="container">
            <div class="row">
                <div class="lhs">
                    <ul>
                        <!-- <li><a href="#!" class="ser-open"><i class="fa fa-search" aria-hidden="true"></i></a></li> -->
                        <li><a href="about-us">About</a></li>
                        <li><a href="faq">FAQ</a></li>
                        <li><a href="terms-conditions">Terms and Conditions</a></li>
                    </ul>
                </div>
                <div class="rhs">
                    <ul>
                        <li><a href="tel:+9949100501"><i class="fa fa-phone" aria-hidden="true"></i>&nbsp;{{  admin.phone  }}</a></li>
                        <li><a href="mailto:vanniyamatrimony@structers.com"><i class="fa fa-envelope-o"
                                    aria-hidden="true"></i>&nbsp; {{ admin.email }}</a></li>
                        <!-- <li><a href="#!"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                        <li><a href="#!"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                        <li><a href="#!"><i class="fa fa-whatsapp" aria-hidden="true"></i></a></li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>

    

    <div class="hom-top">
        <div class="container">
            <div class="row">
                <div class="hom-nav" style="display: flex; flex-direction: column;">
                   <div >
                    <div class="logo" style="cursor: pointer;">
                        <!-- <span class="menu desk-menu">
                            <i></i><i></i><i></i>
                        </span> -->
                        <img
                    src="images/log.jpeg"
                    alt=""
                    loading="lazy"
                    @click="navigateToHome"
                    style="height: 60px;weight: 60px"
                >
                
                   
                </div>
                <div style="float: right;display:flex;height: 100%;justify-content: center; align-items: center;">
                    <button @click="logout" v-if="isUserLoggedIn" style="padding: 10px 20px;background-color: #533A07;color: white">Log Out</button>
                    <button @click="navigateToSignIn" v-else style="padding: 10px 20px;background-color: #533A07;color: white">Log In</button>
                </div>
                
                    

                    
                        <!-- <ul>
                             <li class="smenu-pare">
                                <span class="smenu">Explore</span>
                                <div class="smenu-open smenu-box">
                                    <div class="container">
                                        <div class="row">
                                            <h4 class="tit">Explore category</h4>
                                            <ul>
                                                <li>
                                                    <div class="menu-box menu-box-2">
                                                        <h5>Browse profiles <span>1200+ Verified profiles</span></h5>
                                                        <span class="explor-cta">More details</span>
                                                        <a href="all-profiles.html" class="fclick"></a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="menu-box menu-box-1">
                                                        <h5>Wedding page <span>Make reservation</span></h5>
                                                        <span class="explor-cta">More details</span>
                                                        <a href="wedding.html" class="fclick"></a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="menu-box menu-box-3">
                                                        <h5>All Services<span>Lorem ipsum dummy</span></h5>
                                                        <span class="explor-cta">More details</span>
                                                        <a href="services.html" class="fclick"></a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="menu-box menu-box-4">
                                                        <h5>Join Now <span>Lorem ipsum dummy</span></h5>
                                                        <span class="explor-cta">More details</span>
                                                        <a href="plans.html" class="fclick"></a>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="smenu-pare">
                                <span class="smenu">All pages</span>
                                <div class="smenu-open smenu-multi">
                                    <div class="container">
                                        <div class="row">
                                            <div class="multi-col">
                                                <div class="inn">
                                                    <h4>Page sets 1</h4>
                                                    <ul>
                                                        <li><a href="all-profiles.html">All profiles</a></li>
                                                        <li><a href="profile-details.html">Profile details</a></li>
                                                        <li><a href="wedding.html">Wedding</a></li>
                                                        <li><a href="wedding-video.html">Wedding video</a></li>
                                                        <li><a href="services.html">Our Services</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="multi-col">
                                                <div class="inn">
                                                    <h4>Page sets 2</h4>
                                                    <ul>
                                                        <li><a href="plans.html">Pricing plans</a></li>
                                                        <li><a href="login.html">Login</a></li>
                                                        <li><a href="sign-up.html">Sign-up</a></li>
                                                        <li><a href="photo-gallery.html">Photo gallery</a></li>
                                                        <li><a href="photo-gallery-1.html">Photo gallery 1</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="multi-col">
                                                <div class="inn">
                                                    <h4>Page sets 3</h4>
                                                    <ul>
                                                        <li><a href="contact.html">Contact</a></li>
                                                        <li><a href="about.html">About</a></li>
                                                        <li><a href="blog.html">Blog</a></li>
                                                        <li><a href="blog-detail.html">Blog detail</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="multi-col">
                                                <div class="inn">
                                                    <h4>Page sets 4</h4>
                                                    <ul>
                                                        <li><a href="enquiry.html">Ask your doubts</a></li>
                                                        <li><a href="make-reservation.html">Make Reservation</a></li>
                                                        <li><a href="faq.html">FAQ</a></li>
                                                        <li><a href="coming-soon.html" target="_blank">Coming soon</a>
                                                        </li>
                                                        <li><a href="404.html">404</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="multi-col full">
                                                <div class="inn">
                                                    <h4>User dashboard pages</h4>
                                        <ul>
                                            <li><a href="user-dashboard.html">Dashboard</a></li>
                                            <li><a href="user-profile.html">My profile</a></li>
                                            <li><a href="user-interests.html">Interests</a></li>
                                            <li><a href="user-chat.html">Chat lists</a></li>
                                            <li><a href="user-plan.html">My plan details</a></li>
                                            <li><a href="user-setting.html">Profile settings</a></li>
                                            <li><a href="user-profile-edit.html">Edit full profile</a></li>
                                            <li><a href="login.html">Sign in</a></li>
                                        </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="smenu-pare">
                                <span class="smenu">Top pages</span>
                                <div class="smenu-open smenu-single">
                                    <ul>
                                        <li><a href="all-profiles.html">All profiles</a></li>
                                        <li><a href="profile-details.html">Profile details</a></li>
                                        <li><a href="wedding.html">Wedding</a></li>
                                        <li><a href="blog.html">Blog</a></li>
                                        <li><a href="blog-detail.html">Blog detail</a></li>
                                        <li><a href="about.html">About</a></li>
                                        <li><a href="contact.html">Contact</a></li>
                                        <li><a href="photo-gallery.html">Photo gallery</a></li>
                                        <li><a href="photo-gallery-1.html">Photo gallery 1</a></li>
                                        <li><a href="login.html">Login</a></li>
                                        <li><a href="sign-up.html">Sign-up</a></li>
                                        <li><a href="plans.html">Pricing plans</a></li>
                                    </ul>
                                </div>
                            </li>
                            <li><a href="plans.html">Plans</a></li>
                            <li><a href="sign-up.html">Register</a></li>
                            <li class="smenu-pare">
                                <span class="smenu">Dashboard</span>
                                <div class="smenu-open smenu-single">
                                    <ul>
                                        <li><a href="user-dashboard.html">Dashboard</a></li>
                                        <li><a href="user-profile.html">My profile</a></li>
                                        <li><a href="user-interests.html">Interests</a></li>
                                        <li><a href="user-chat.html">Chat lists</a></li>
                                        <li><a href="user-plan.html">My plan details</a></li>
                                        <li><a href="user-setting.html">Profile settings</a></li>
                                        <li><a href="user-profile-edit.html">Edit full profile</a></li>
                                        <li><a href="login.html">Sign in</a></li>
                                    </ul>
                                </div>
                            </li>
                        </ul> -->
                    </div>
                    <!--END MOBILE MENU-->
                </div>
            </div>
        </div>
    </div>

</template>


<script>

import { mapState } from 'vuex';
import { signOut } from 'aws-amplify/auth'
export default {
  name: 'AppHeader',
  computed: {
    ...mapState(['isUserLoggedIn', 'user', 'admin'])
  },
  methods: {
    navigateToSignIn() {
      // Use the router object to navigate to the /about route
      this.$router.push('/signin');
    },
    navigateToHome() {
      // Use the router object to navigate to the /about route
      this.$router.push('/');
    },
    async logout() {
        let loader = this.$loading.show();
        try {
            await signOut()
            this.$store.commit("logout") 
            this.$toast.success("User Logged out")
            this.$router.push('/');
        } catch {
            this.$toast.error("Error Logging out")
        }
        loader.hide()
    }
  },
//   props: {
//     msg: String
//   }
}
</script>


    <!-- `,
    computed: {
        adminName() {
          // Access the store state in your component
          return store.state.admin.name;
        },
      },
    // Component logic goes here
  }); -->