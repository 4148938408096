<template>
    <section>
        <div class="str">
            <div class="ban-inn ab-ban" style="background-color: black;">
                <div class="container">
                    <div class="row">
                        <div class="hom-ban" >
                            <div class="ban-tit" style="justify-content: center;align-items: center;">
                                <span>Connecting Hearts, Creating Futures</span><br />
                                <!-- <h1>About us</h1> -->
                                <span style="color: white">Our platform is dedicated to help individuals find their perfect life partners, creating meaningful connections that last a lifetime.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- END -->

    <!-- START -->
    <section>
        <div class="ab-sec2">
            <div class="container">
                <div class="row">
                    <ul>
                        <li>
                            <div>
                                <img src="images/icon/prize.png" alt="">
                                <h4>Genuine profiles</h4>
                                <p>We make sure to keep our portal with genuine and valid profiles</p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <img src="images/icon/trust.png" alt="">
                                <h4>Most trusted</h4>
                                <p>We make sure that your data is secure. No broker in middle</p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <img src="images/images.png" alt="">
                                <h4>Heritage</h4>
                                <p>Celebrating the heritage, culture, and values of the Vanniyar people</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <!-- END -->

    <!-- START -->
    <section>
        <div class="ab-wel">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="ab-wel-lhs">
                            <span class="ab-wel-3"></span>
                            <img src="images/about/aboutus3.jpeg" alt="" class="ab-wel-1">
                            <img src="images/about/aboutus2.jpg" alt="" class="ab-wel-2">
                            <span class="ab-wel-4"></span>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="ab-wel-rhs">
                            <div class="ab-wel-tit">
                                <h2> Our Mission</h2>
                                <p>Our mission is simple yet profound: to facilitate the journey of finding love and companionship. We understand the significance of marriage in one's life, and we are committed to providing a safe, secure, and user-friendly platform where individuals can explore, connect, and build relationships based on trust, respect, and compatibility. </p>
                            </div>
                            <div class="ab-wel-tit-1">
                                <p>At Vanniya Matrimony, we strive for excellence in everything we do. We provide detailed profiles that enable individuals to showcase their personalities, interests, and preferences, allowing for more meaningful connections.We prioritize the privacy and security of our users. Our platform employs stringent measures to safeguard personal information and ensure a safe online environment for all members.</p>
                            </div>
                            <div class="ab-wel-tit-2">
                                <ul>
                                    <li>
                                        <div>
                                            <i class="fa fa-phone" aria-hidden="true"></i>
                                            <h4>Enquiry <em style="font-size:13px">+91 9949100501</em></h4>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <i class="fa fa-envelope-o" aria-hidden="true"></i>
                                            <h4>Get Support <em style="font-size:13px">vanniyamatrimony@structers.com</em></h4>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section>
        <MembersPage></MembersPage>
    </section>
    <!-- END -->
</template>

<script>
import MembersPage from "@/components/Members.vue";
export default {
  name: 'AboutUs',
  components: {
    MembersPage,
  },
}
</script>