// authGuard.js

import { getCurrentUser } from 'aws-amplify/auth';
import store from "../store"

export const authGuard = async (to, from, next) => {
  try {
    const currentUser = await getCurrentUser();
    // const session = await fetchAuthSession();
    if (currentUser) {
      // If user is authenticated, allow navigation to the route
      store.commit("addUser", {
        "username": currentUser.username,
        "id": currentUser.userId,
        "email": currentUser?.signInDetails?.loginId
      })
      if(to.path == "/signin") {
        next("/user-onboard")
      } else {
        next();
      }
      
    } else {
      // If user is not authenticated, redirect to login page
      next('/signin');
    }
  } catch (error) {
    console.error('Error fetching current user:', error);
    next('/signin');
  }
};
