<template>
    <section>
        <div class="login">
            <div class="container">
                <div class="row">

                    <div class="inn">
                        <div class="lhs">
                            <div class="tit">
                                <h2>Now <b>Find your life partner</b> Easy and fast.</h2>
                            </div>
                            <div class="im">
                                <img src="images/login-couple.png" alt="">
                            </div>
                            <div class="log-bg">&nbsp;</div>
                        </div>
                        <div class="rhs">
                            <div>
                                <div class="form-tit">
                                    <h4>Start for free</h4>
                                    <h1>Sign up to Matrimony</h1>
                                    <p>Already a member? <RouterLink to="signin">Login</RouterLink></p>
                                </div>
                                <div class="form-login">
                                    <form @submit.prevent="signUp">
                                        <div class="form-group">
                                            <label class="lb">Name:</label>
                                            <input type="text" v-model="username" class="form-control" placeholder="Enter your full name"
                                                name="username"  required>
                                        </div>
                                        <div class="form-group">
                                            <label class="lb">Email:</label>
                                            <input type="email" class="form-control" id="email"
                                                placeholder="Enter email" name="email" v-model="email" required>
                                        </div>
                                        <div class="form-group">
                                            <label class="lb">Phone:</label>
                                            <input type="tel" class="form-control" id="phone" v-model="phone"
                                                placeholder="Enter phone number" name="phone" required>
                                        </div>
                                        <div class="form-group">
                                            <label class="lb">Password:</label>
                                            <input type="password" class="form-control" id="pwd"
                                                placeholder="Enter password" name="pswd" v-model="password" required>
                                        </div>
                                        <div class="form-group form-check">
                                            <label class="form-check-label">
                                                <input class="form-check-input" type="checkbox" name="agree" v-model="isTermsAgreed" required> Creating
                                                an account means you’re okay with our <a href="terms-conditions">Terms of Service</a>,
                                                Privacy Policy, and our default Notification Settings.
                                            </label>
                                        </div>
                                        <button type="submit" class="btn btn-primary">Create Account</button>

                                        <span style="font-size: 12px;" >If you are facing issue in registration Contact: +91 9949100501</span>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>

import { mapState } from 'vuex';
import { signUp as AmplifySignUp, getCurrentUser} from 'aws-amplify/auth';
// import { signUp } from "@/api/users"

export default {
  name: 'SignUp',
  data() {
    return {
        username: '',
        password: '',
        email: '',
        phone: '',
        isTermsAgreed: false
    }
  },
  computed: {
    ...mapState(['isUserLoggedIn', 'user', 'admin'])
  },
  mounted() {
    getCurrentUser().then(currentUser => {
        console.log(currentUser)
        this.$store.commit("addUser", {
        "username": currentUser.username,
        "id": currentUser.userId,
        "email": currentUser?.signInDetails?.loginId
      })
      this.$router.push({"path": "/user-onboard"})
    }).catch((err) => console.log("User Not LoggedIn", err))
  },
  methods: {
    async signUp() {
        let loader = this.$loading.show();
        try {
            // let username_modified = this.username.replaceAll(" ", "")
            let email_part = this.email.split("@")[0]
            let provider = this.email.split("@")[1]
            await AmplifySignUp({
                                    username: `${email_part}:${provider}`,
                                    password: this.password,
                                    options: { userAttributes: {
                                            email: this.email,
                                            phone_number: `+91${this.phone}`}}});
            loader.hide();
            // this.$router.push({"path": "/verify-email", query: { email: this.email }} )
            this.$router.push({"path": "/user-onboard"})


        }catch (error) {
            // console.log(error, error.code, error.name);
            if (error.name === "UsernameExistsException") {
                this.$toast.error("User Already exists Please signin")
            }
            else if (error.name === 'InvalidPasswordException') {
            console.log('Invalid password entered');
                this.$toast.error("Password should have special characters, Numbers and minimum 8 characters")
            } else {
                this.$toast.error(error.message || "Error Creating User. Please try again later!")
            }
            
            loader.hide();
        }
    }
  }
}
</script>
