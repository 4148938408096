<template>
    <section>
        <div class="login pro-edit-update">
            <div class="container">
                <div class="row">
                    <div class="inn">
                        <div class="rhs">
                                <div class="form-login">
                                    <form @submit.prevent="saveBio">
                                        <!--PROFILE BIO-->
                                        <div class="edit-pro-parti">
                                            <div class="form-tit">
                                                <h4>step 1/2 - Profile Creation</h4>
                                                <h1>Enter your info</h1>
                                            </div>
                                            <div class="form-group">
                                                <label class="lb">Full Name:</label>
                                                <input type="text" class="form-control" placeholder="Enter your full name"
                                                    name="full_name" v-model="full_name" required>
                                            </div>
                                            <!-- <div class="form-group">
                                                <label class="lb">Phone:</label>
                                                <input type="number" class="form-control" id="phone"
                                                    placeholder="Enter phone number" name="phone" v-model="phone" disabled>
                                            </div> -->
                                        </div>
                                        <!--END PROFILE BIO-->
                                        <!--PROFILE BIO-->
                                        <div class="edit-pro-parti">
                                            <div class="form-tit">
                                                <h4>your Bio</h4>
                                                <h1>Family and Address</h1>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">Gender:</label>
                                                    <select class="form-select chosen-selects" required data-placeholder="Select your Gender" v-model="gender">
                                                        <option>Male</option>
                                                        <option>Female</option>
                                                      </select>
                                                </div>
                                                <!-- <div class="col-md-6 form-group">
                                                    <label class="lb">City:</label>
                                                    <input type="text" class="form-control" v-model="city" required>
                                                </div> -->
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">Marital Status:</label>
                                                    <select class="form-select chosen-selects" required data-placeholder="Select you marital status" v-model="marital_status">
                                                        <option>Never Married</option>
                                                        <option>Divorced</option>
                                                      </select>
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">Age:</label>
                                                    <input type="number" class="form-control" required v-model="age">
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">Height: (in Centimeter)</label>
                                                    <input type="number" class="form-control" required v-model="height" >
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">Complexion (colour):</label>
                                                    <select class="form-select chosen-selects" required data-placeholder="Select your Color" v-model="complexion" >
                                                        <option></option>
                                                        <option>Fair</option>
                                                        <option>Black</option>
                                                        <option>White</option>
                                                      </select>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">Fathers name:</label>
                                                    <input type="text" class="form-control" required v-model="father_name">
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">Mothers name:</label>
                                                    <input type="text" class="form-control" required  v-model="mother_name" >
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">Father's Occupation:</label>
                                                    <input type="text" class="form-control" required v-model="father_occupation" >
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">Mother's Occupation:</label>
                                                    <input type="text" class="form-control" required  v-model="mother_occupation">
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">Total Brothers:</label>
                                                    <input type="number" class="form-control" required  v-model="total_brothers">
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">Total Sisters:</label>
                                                    <input type="number" class="form-control" required v-model="total_sisters">
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">Total Brothers Married:</label>
                                                    <input type="number" class="form-control" required  v-model="total_brothers_married">
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">Total Sisters Married:</label>
                                                    <input type="number" class="form-control" required  v-model="total_sisters_married">
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="lb">Address:</label>
                                                <textarea type="text" class="form-control"  required v-model="address"></textarea>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">State:</label>
                                                    <select class="form-select" required data-placeholder="Select State" v-model="state">
                                                        <!-- <option>Male</option>
                                                        <option>Female</option> -->
                                                        <option v-for="istate in states" :value='istate' :key="istate.isoCode">
                                                            {{ istate.name }}
                                                        </option>
                                                      </select>
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">City:</label>
                                                    <select class="form-select "  required data-placeholder="Select City" v-model="city">
                                                        <option v-for="icity in cities" :value='icity' :key="icity.isoCode">
                                                            {{ icity.name }}
                                                        </option>
                                                      </select>
                                                </div>
                                            </div>
                                        </div>
                                        <!--END PROFILE BIO-->
                                        <!--PROFILE BIO-->

                                        <div class="edit-pro-parti">
                                            <div class="form-tit">
                                                <h4>Astrology</h4>
                                                <h1>Your Astrology and Birth</h1>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">Date of birth:</label>
                                                    <input type="date" class="form-control" required  v-model="dob">
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">Time of birth:</label>
                                                    <input type="time" class="form-control" required  v-model="tob">
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">Place of birth:</label>
                                                    <input type="text" class="form-control" required  v-model="pob">
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">Nakshathram:</label>
                                                    <input type="text" class="form-control" required v-model="nakshatram">
                                                </div><div class="col-md-6 form-group">
                                                    <label class="lb">Rasi:</label>
                                                    <select class="form-select chosen-selects"  required data-placeholder="Select your Rasi"  v-model="rasi">
                                                        <option></option>
                                                        <option>Aries / మేషం / மேஷம்</option>
                                                        <option>Taurus / వృషభం / ரிஷபம்</option>
                                                        <option>Gemini / మిథునం / மிதுனம்</option>
                                                        <option>Cancer / కర్కాటకం / கடகம்</option>
                                                        <option>Leo / సింహం / சிம்மம்</option>
                                                        <option>Virgo / కన్యా / கன்னி</option>
                                                        <option>Libra / తులారాశి / துலாம்</option>
                                                        <option>Scorpio / వృశ్చికం / விருச்சிகம்</option>
                                                        <option>Sagittarius / ధనుస్సు / தனுசு</option>
                                                        <option>Capricorn / మకరరాశి / மகரம்</option>
                                                        <option>Aquarius / కుంభరాశి / கும்பம்</option>
                                                        <option>Pisces / మీనరాశి / மீனம்</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">Gothram:</label>
                                                    <input type="text" class="form-control" required  v-model="gothram" >
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">Caste:</label>
                                                    <input type="text" class="form-control" required value="Vanniya Kula Kshatriya" disabled>
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">Sub Caste:</label>
                                                    <input type="text" class="form-control" required  v-model="sub_caste">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="edit-pro-parti">
                                            <div class="form-tit">
                                                <h4>Job details</h4>
                                                <h1>Job & Education</h1>
                                            </div>
                                            <div class="form-group">
                                                <label class="lb">Job type:</label>
                                                <select class="form-select chosen-selects" required data-placeholder="Select Job Type"  v-model="job_type"> 
                                                    <option>Business</option>
                                                    <option>Private Employee</option>
                                                    <option>Government</option>
                                                  </select>
                                            </div>
                                            <div class="form-group">
                                                <label class="lb">Company name:</label>
                                                <input type="text" class="form-control" required v-model="company_name">
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">Annual Salary:</label>
                                                    <input type="number" class="form-control" required  v-model="annual_salary">
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">Job total experience: (in years)</label>
                                                    <input type="number" class="form-control" required  v-model="total_job_exp">
                                                </div>
            
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">Working State:</label>
                                                    <select class="form-select" required data-placeholder="Select State" v-model="working_state">
                                                        <!-- <option>Male</option>
                                                        <option>Female</option> -->
                                                        <option v-for="istate in working_states" :value='istate' :key="istate.isoCode">
                                                            {{ istate.name }}
                                                        </option>
                                                      </select>
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">Working City:</label>
                                                    <select class="form-select"  required data-placeholder="Select City" v-model="working_city">
                                                        <option v-for="icity in working_cities" :value='icity' :key="icity.isoCode">
                                                            {{ icity.name }}
                                                        </option>
                                                      </select>
                                                </div>
                                            </div>
                                            
                                            <div class="row">
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">Degree:</label>
                                                    <input type="text" class="form-control" required v-model="degree">
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">College:</label>
                                                    <input type="text" class="form-control" required  v-model="college">
                                                </div>
                                            </div>
                                        </div>
                                        <!--END PROFILE BIO-->
                                        <!--PROFILE BIO-->
                                        <!-- <div class="edit-pro-parti">
                                            <div class="form-tit">
                                                <h4>Media</h4>
                                                <h1>Social media</h1>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">WhatsApp:</label>
                                                    <input type="text" class="form-control">
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">Facebook:</label>
                                                    <input type="text" class="form-control" >
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">Instagram:</label>
                                                    <input type="text" class="form-control">
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">X:</label>
                                                    <input type="text" class="form-control" >
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">Youtube:</label>
                                                    <input type="text" class="form-control">
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">Linkedin:</label>
                                                    <input type="text" class="form-control" >
                                                </div>
                                            </div>
                                        </div> -->
                                        <!--END PROFILE BIO-->
                                        <!--PROFILE BIO-->
                                        <div class="edit-pro-parti">
                                            <div class="form-tit">
                                                <h4>interests</h4>
                                                <h1>Hobbies</h1>
                                            </div>
                                            <div class="multiselect-wrapper">
                                                <div class="form-group">
                                                    <Multiselect v-model="hobbies" :close-on-select="false"  :multiple="true" :options="available_hobbies" ></Multiselect>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="edit-pro-parti">
                                            <div class="form-tit">
                                                <h4>Other Details (optional)</h4>
                                                <h1>Mention details like property, conditions or dosha information</h1>
                                            </div>
                                            <div class="row">
                                                <div class="form-group">
                                                <label class="lb">Other Details:</label>
                                                <textarea type="text" class="form-control"  v-model="other_details"></textarea>
                                            </div>
                                            </div>
                                        </div>
                                        <!--END PROFILE BIO-->
                                        <button type="submit" class="btn btn-primary">Proceed to Photo Upload and Preferences</button>
                                    </form>
                                </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>

import { mapState } from 'vuex';
import { updateUserBio, getData } from "@/api/users";
import { State, City } from "country-state-city"
import Multiselect from 'vue-multiselect';
export default {
  name: 'UserProfile',
  components: {
    Multiselect
  },
  data() {
    let user = this.$store.state.user
    console.log(user)
    let states = State.getStatesOfCountry("IN")
    let cities = []
    let working_state = State.getStatesOfCountry("IN")
    let working_cities = []
    let available_hobbies = [
    "Modelling",
    "Watching",
    "movies",
    "Playing",
    "volleyball",
    "Hangout with family",
    "Adventure Tavel",
    "Books",
    "Music",
    "Cooking",
    "Yoga",
    ]
    // let cities = City.getCitiesOfCountry("IN")
    // console.log(cities)
    return {
        available_hobbies: available_hobbies,
      states:states,
      cities: cities,
      working_states: working_state,
      working_cities: working_cities,
      full_name: '',
      email: user.email,
      phone: user.phone,
      gender: '',
      city: '',
      state: '',
      marital_status: '',
      age: '',
      complexion: '',
      father_name: '',
      mother_name: '',
      father_occupation: '',
      mother_occupation: '',
      total_brothers: '',
      total_sisters: '',
      total_brothers_married: '',
      total_sisters_married: '',
      address: '',
      dob: '',
      tob: '',
      pob: '',
      rasi: '',
      nakshatram: '',
      gothram: '',
      caste: 'Vanniya Kula Kshatriya',
      sub_caste: '',
      job_type: '',
      company_name: '',
      annual_salary: '',
      total_job_exp: '',
      degree: '',
      college: '',
      hobbies: [],
      other_details: '',
      working_state: '',
      working_city: '',
      height: '',
      payload: {}
    };
  },
  watch: {
    state() {
      // Run your function here
      console.log("triggered")
      this.getCities();
    },
    working_state() {
        console.log("Aslo")
        this.getWorkingCities()
    }
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    ...mapState(['isUserLoggedIn', 'user', 'admin'])
  },
  methods: {
    async fetchData() {
        let loader = this.$loading.show();
        let res = await getData(`${this.$store.state.user.id}.json`)
        console.log(res)
        
        if(res) { 
            res = res.bio
            this.payload = res
        }
        this.full_name = res?.full_name
        this.email = res?.email
        this.phone = res?.phone
        this.gender = res?.gender
        this.state = res?.state || {}
        this.city = res?.city
        this.marital_status = res?.marital_status
        this.age = res?.age
        this.complexion = res?.complexion
        this.father_name = res?.father_name
        this.mother_name = res?.mother_name
        this.father_occupation = res?.father_occupation
        this.mother_occupation = res?.mother_occupation
        this.total_brothers = res?.total_brothers
        this.total_sisters = res?.total_sisters
        this.total_brothers_married = res?.total_brothers_married
        this.total_sisters_married = res?.total_sisters_married
        this.address = res?.address
        this.dob = res?.dob
        this.tob = res?.tob
        this.pob = res?.pob
        this.rasi = res?.rasi
        this.nakshatram = res?.nakshatram
        this.gothram = res?.gothram
        this.caste = res?.caste
        this.sub_caste = res?.sub_caste
        this.job_type = res?.job_type
        this.company_name = res?.company_name
        this.annual_salary = res?.annual_salary
        this.total_job_exp = res?.total_job_exp
        this.degree = res?.degree
        this.college = res?.college
        this.hobbies = res?.hobbies
        this.other_details = res?.other_details
        this.working_state = res?.working_state
        this.working_city = res?.working_city
        this.height = res?.height
        loader.hide()
    },
    getCities() {
        if(this.state?.isoCode) {
            let _cities = City.getCitiesOfState("IN", this.state?.isoCode)
        this.cities = _cities;
        }
        
    },
    getWorkingCities() {
        if(this.working_state?.isoCode) {
            let _cities = City.getCitiesOfState("IN", this.working_state?.isoCode)
        this.working_cities = _cities;
        }
        
    },
    async saveBio() {
        let loader = this.$loading.show();
        // const getUser = 
        const user_bio = {
            full_name: this.full_name,
            email: this.email,
            phone: this.phone,
            gender: this.gender,
            city: this.city,
            state: this.state,
            marital_status: this.marital_status,
            age: this.age,
            complexion: this.complexion,
            father_name: this.father_name,
            mother_name: this.mother_name,
            father_occupation: this.father_occupation,
            mother_occupation: this.mother_occupation,
            total_brothers: this.total_brothers,
            total_sisters: this.total_sisters,
            total_brothers_married: this.total_brothers_married,
            total_sisters_married: this.total_sisters_married,
            address: this.address,
            dob: this.dob,
            tob: this.tob,
            pob: this.pob,
            rasi: this.rasi,
            nakshatram: this.nakshatram,
            gothram: this.gothram,
            caste: this.caste,
            sub_caste: this.sub_caste,
            job_type: this.job_type,
            company_name: this.company_name,
            annual_salary: this.annual_salary,
            total_job_exp: this.total_job_exp,
            degree: this.degree,
            college: this.college,
            hobbies: this.hobbies,
            other_details: this.other_details,
            working_state: this.working_state,
            working_city: this.working_city,
            height: this.height
        }
        try {
            console.log(user_bio)
            await updateUserBio(`${this.$store.state.user.id}.json`, JSON.stringify({
                "bio": {...this.payload ,...user_bio}
            }))
            loader.hide(); 
            this.$toast.success("Bio Updated Successfully")
            this.$router.push("/user-gallery")
        } catch (error) {
            console.log(error);
            this.$toast.error("Error Updating your bio. Please try after sometime")
            loader.hide();
        }
    }
  }
}


</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<!-- <style src="vue-multiselect/dist/vue-multiselect.min.css"></style> -->