<template>
    <section>
        <div class="login">
            <div class="container">
                <div class="row">

                    <div class="inn">
                        <div class="lhs">
                            <div class="tit">
                                <h2>Now <b>Find <br> your life partner</b> Easy and fast.</h2>
                            </div>
                            <div class="im">
                                <img src="images/login-couple.png" alt="">
                            </div>
                            <div class="log-bg">&nbsp;</div>
                        </div>
                        <div class="rhs">
                            <div>
                                <div class="form-tit">
                                    <h4>Start for free</h4>
                                    <h1>Sign in to Matrimony</h1>
                                    <p>Not a member? <RouterLink to="/register">Sign up now</RouterLink></p>
                                </div>
                                <div class="form-login">
                                    <form @submit.prevent="signIn">
                                        <div class="form-group">
                                            <label class="lb">Email:</label>
                                            <input v-model="email" type="email" class="form-control" id="email"
                                                placeholder="Enter email" name="email" required>
                                        </div>
                                        <div class="form-group">
                                            <label class="lb">Password:</label>
                                            <input v-model="password" type="password" class="form-control" id="pwd"
                                                placeholder="Enter password" name="pswd" required>
                                        </div>
                                        <div class="form-group form-check">
                                            <label class="form-check-label">
                                                <input  v-model="remember_me" class="form-check-input" type="checkbox" name="agree"> Remember
                                                me
                                            </label>
                                        </div>
                                        <button type="submit" class="btn btn-primary">Sign in</button>

                                        <div class="form-tit">
                                            <span style="font-size: 12px; " >If you are facing issue logging in Contact: +91 9949100501</span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>

import { mapState } from 'vuex';
import { signIn as AmplifySignIn, getCurrentUser} from 'aws-amplify/auth';

export default {
  name: 'SignIn',
  data() {
    return {
      email: '',
      password: '',
      remember_me: false
    };
  },
  computed: {
    ...mapState(['isUserLoggedIn', 'user', 'admin'])
  },

  mounted() {
    getCurrentUser().then(currentUser => {
        console.log(currentUser)
        this.$store.commit("addUser", {
        "username": currentUser.username,
        "id": currentUser.userId,
        "email": currentUser?.signInDetails?.loginId
      })
      this.$router.push({"path": "/user-onboard"})
    }).catch((err) => console.log("User Not LoggedIn", err))
  },
  methods: {
    signIn() {
        let loader = this.$loading.show();
        AmplifySignIn({
            username: this.email, 
            password: this.password
        }).then(() => {
            getCurrentUser().then(currentUser => {
                this.$store.commit("addUser", {
                "username": currentUser.username,
                "id": currentUser.userId,
                "email": currentUser.signInDetails.loginId
              })
              this.$router.push({"path": "/user-onboard"})
              this.$toast.success("Logged In")
            }).catch((error) => {
                // console.log(err)
                // console.log("User Not LoggedIn")
                this.$toast.error(error.message ||"Error Signing In. Please check the username and password")
            })
            loader.hide()
        }).catch(() => {
            this.$toast.error("Error Signing In. Please check the username and password")
            loader.hide()
        })
    }
  }

}
</script>
