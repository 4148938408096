const awsconfig = {
    Auth: {
      Cognito: {
        //  Amazon Cognito User Pool ID
        userPoolId: 'ap-south-1_fjFgd6RXy',
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolClientId: '71fhd03tsllnc9816hshedauib',
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: 'ap-south-1:d4e86a22-4e6e-4e05-8a5d-c4848b35c9aa',
        // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
        // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
        signUpVerificationMethod: 'code', // 'code' | 'link'
        // loginWith: {
        //   // OPTIONAL - Hosted UI configuration
        //   oauth: {
        //     domain: 'your_cognito_domain',
        //     scopes: [
        //       'phone',
        //       'email',
        //       'profile',
        //       'openid',
        //       'aws.cognito.signin.user.admin'
        //     ],
        //     redirectSignIn: ['http://localhost:3000/'],
        //     redirectSignOut: ['http://localhost:3000/'],
        //     responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        //   }
        // }
      }
    },
    Storage: {
      S3: {
        "bucket": "vanniya-matrimony",
        "region": "ap-south-1"
      }
    }
  }

  export default awsconfig;

  //719455292350