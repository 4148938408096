<template>
    <section>
        <div class="ab-team pg-abo-ab-team">
            <div class="container">
                <div class="row">
                    <div class="home-tit">
                        <p>OUR TEAM</p>
                        <h2><span>Meet Our Team</span></h2>
                        <span class="leaf1"></span>
                    </div>
                    <ul>
                        <li>
                            <div  style="width: 260px;">
                                <img src="images/crrajan.jpeg" alt="" loading="lazy">
                                <h4>C.R Rajan</h4>
                                <p>Community Leader</p>
                                <!-- <ul class="social-light">
                                    <li><a href="#!"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                                    <li><a href="#!"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                    <li><a href="#!"><i class="fa fa-whatsapp" aria-hidden="true"></i></a></li>
                                    <li><a href="#!"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                    <li><a href="#!"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                                </ul> -->
                            </div>
                        </li>
                        <li>
                            <div style="width: 260px;">
                                <img src="images/ammu.jpg" alt="" loading="lazy">
                                <h4>Thejaswi Lokesh</h4>
                                <p>Director</p>
                                <!-- <ul class="social-light">
                                    <li><a href="#!"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                                    <li><a href="#!"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                    <li><a href="#!"><i class="fa fa-whatsapp" aria-hidden="true"></i></a></li>
                                    <li><a href="#!"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                    <li><a href="#!"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                                </ul> -->
                            </div>
                        </li>
                        <li>
                            <div  style="width: 260px;">
                                <img src="images/mohan.jpeg" alt="" loading="lazy">
                                <h4>R Mohan</h4>
                                <p>Advisor</p>
                                <!-- <ul class="social-light">
                                    <li><a href="#!"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                                    <li><a href="#!"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                    <li><a href="#!"><i class="fa fa-whatsapp" aria-hidden="true"></i></a></li>
                                    <li><a href="#!"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                    <li><a href="#!"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                                </ul> -->
                            </div>
                        </li>
                        <li>
                            <div  style="width: 260px;" >
                                <img src="images/rajendran-new.jpeg" alt="" loading="lazy">
                                <h4>N Rajendran</h4>
                                <p>Advisor</p>
                                <!-- <ul class="social-light">
                                    <li><a href="#!"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                                    <li><a href="#!"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                    <li><a href="#!"><i class="fa fa-whatsapp" aria-hidden="true"></i></a></li>
                                    <li><a href="#!"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                    <li><a href="#!"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                                </ul> -->
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'MembersPage',
  }
</script>
