<template>
    <section>
        <div class="login pro-edit-update">
            <div class="container">
                <div class="row">
                    <div class="inn">
                        <div class="rhs">
                                <div class="form-login">
                                    <form @submit.prevent="save">
                                        <!--PROFILE BIO-->
                                        <div class="edit-pro-parti">
                                            <div class="form-tit">
                                                <h4>step 2/2 - Gallery and Privacy</h4>
                                                <h1>Upload your Photos</h1>
                                            </div>
                                            <div style="margin-bottom: 20px;" >
                                                <div class=" btn btn-primary" >
                                                    <label for="file-input" class="upload-label">
                                                      <span class="icon">&#x1F4E5;</span> <!-- Unicode for file icon, adjust as needed -->
                                                      <span class="text">Select your photos (You can choose multiple photos)</span>
                                                    </label>
                                                    <input
                                                      type="file"
                                                      @click="$event.target.value=''"
                                                      id="file-input"
                                                      class="file-input"
                                                      multiple
                                                      @change="handleFileChange"
                                                    />
                                                  </div>
                                            </div>
                                            <div v-if="photos.length">
                                                <PhotoGallery :photos="photos" @remove-photo="removePhoto"></PhotoGallery>
                                            </div>
                                           
                                        </div>
                                        
                                        <!--END PROFILE BIO-->
                                        <button type="submit" class="btn btn-primary">Complete</button>
                                    </form>
                                </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>

// import { mapState } from 'vuex';
// import { signIn as AmplifySignIn} from 'aws-amplify/auth';
import PhotoGallery from "@/components/gallery/PhotoGallery.vue";
import { uploadPhoto } from "@/api/users";
import { updateUserBio, getData, getImage } from "@/api/users";

export default {

  name: 'UserGallery',
  components: {
    PhotoGallery
  },
  methods: {
    resetImageUploader() {
                this.$refs.imageUploader.value = '';
    },
    removePhoto(index) {
      // Remove the photo from the photos array
      this.photos.splice(index, 1);

      // If the removed photo was the active one, update activePhoto
      if (index === this.activePhoto && this.photos.length > 0) {
        this.activePhoto = Math.min(this.activePhoto, this.photos.length - 1);
      } else if (this.photos.length === 0) {
        this.activePhoto = null; // No active photo if there are no photos
      }
    },
    handleFileChange(event) {
      // Handle the file change event here if needed
      console.log("files", event.target.files)
      Array.from(event.target.files).forEach((file)=> {
        console.log(file);
        console.log(file.name)
        const reader = new FileReader();
        reader.onload = (e) => {
          // this.photos.push(e.target.result)
          this.photos.push({
            "name": file.name,
            "data": e.target.result,
            "file": file,
            "type": file.type
          })
        };
        reader.readAsDataURL(file)
      })
      console.log('Selected file:', this.photos);
    },

    async save() {
      let loader = this.$loading.show();
      this.photos.forEach(async x => {
        if(x.file) {
          await uploadPhoto(x?.name, x.file)
        }
      } )

      try {
            let userPhotos = [];
            this.photos.forEach(x => {
              userPhotos.push({
                "name": x.name,
                "type": x.type
              })
            })
            await updateUserBio(`${this.$store.state.user.id}.json`, JSON.stringify({
                "bio": {...this.payload , photos: userPhotos}
            }))
            loader.hide(); 
            this.$toast.success("Bio Updated Successfully")
            this.$router.push("/finish")
        } catch (error) {
            console.log(error);
            this.$toast.error("Error Updating your bio. Please try after sometime")
            loader.hide();
        }
    },
    async fetchData() {
        let loader = this.$loading.show();
        let res = await getData(`${this.$store.state.user.id}.json`)
        
        if(res) { 
            res = res.bio
            this.payload = res
        }
        let _photos = res?.photos || []
        _photos?.forEach(async x => {
          let photoRes = await getImage(x.name)
          const reader = new FileReader();
          reader.readAsDataURL(photoRes);
          reader.onload = () => {
            const dataURL = reader.result;
            // console.log('Data URL:', dataURL);
            this.photos.push({
            ...x,
            data: dataURL
          })
            // Use the data URL as needed
          };         
        })
        loader.hide()
    }
  },
  
  data() {
    return {
        photos: [],
        payload: {}
    }
    
  },
  mounted() {
    this.fetchData();
  }
}
</script>

<style scoped>

.upload-button {
  display: inline-block;
}

.upload-label {
  /* display: inline-block; */
  /* background-color: #4CAF50; Green color, adjust as needed */
  color: #ffffff; /* White text, adjust as needed */
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}



.file-input {
  display: none;
  width: 100%;
}

.icon {
  margin-right: 8px;
}

.text {
  font-size: 16px;
  color: black;
}
</style>
