import { createApp } from 'vue'
import App from './App.vue'
import store from './store';
import router from './router';
import { Amplify } from 'aws-amplify'
import awsconfig from './ampliy-config.js';
import {LoadingPlugin} from 'vue-loading-overlay';
import ToastPlugin from 'vue-toast-notification';
import 'vue-loading-overlay/dist/css/index.css';
import 'vue-toast-notification/dist/theme-bootstrap.css';

Amplify.configure(awsconfig)

const app = createApp(App)
app.use(ToastPlugin, {
    // One of the options
    position: 'top'
});
app.use(LoadingPlugin);
app.use(store)
app.use(router)
app.mount('#app')
