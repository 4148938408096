<template>
    <div class="vueGallery">
    <div class="activePhoto" :style="'background-image: url('+photos[activePhoto]?.data+');'">
      <button type="button" aria-label="Previous Photo" class="previous" @click="previousPhoto()">
        <i class="fas fa-chevron-circle-left"></i>
      </button>
      <button type="button" aria-label="Next Photo" class="next" @click="nextPhoto()">
        <i class="fas fa-chevron-circle-right"></i>
      </button>
    </div>
    <div class="thumbnails">
      
      <div
           v-for="(photo, index) in photos"
           :src="photo?.data"
           :key="index"
           @click="activePhoto = index"
           :class="{'active': activePhoto == index}" :style="'background-image: url('+photo?.data+');position: relative'">
          <!-- "x" button -->
          <!-- <button class="make-primary" @click.prevent="removePhoto(index)" :style="'font-size: 7px'">make primary</button> -->
          <button class="close-button" @click.prevent="removePhoto(index)">&times;</button>
      </div>
    </div>
  </div>
</template>

<script>

import "./gallery.scss"

export default {
  name: 'PhotoGallery',
  props: ['photos'],
  data: function () {
    return {
      activePhoto: null
    }
  },
  mounted () {
    this.activePhoto = 0
    document.addEventListener("keydown", (event) => {
      if (event.which == 37)
        this.previousPhoto()
      if (event.which == 39)
        this.nextPhoto()
    })
  },
  methods: {
    nextPhoto () {
      this.activePhoto = ( this.activePhoto+1 < this.photos.length ? this.activePhoto+1 : 0 )
    },
    previousPhoto () {
      this.activePhoto = ( this.activePhoto-1 >= 0 ? this.activePhoto-1 : this.photos.length-1 )
    },
    removePhoto(index) {
      // Emit an event to notify the parent to remove the photo
      this.$emit('remove-photo', index);
    },
   
  }
}
</script>
<style scoped>
.close-button {
  position: absolute;
  top: -5px;
  right: 5px;
  background-color: transparent;
  border: none;
  color: black; /* Change the color as needed */
  font-size: 30px;
  cursor: pointer;
}

.make-primary {
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: green;
  padding: 5px;
  border: none;
  color: black; /* Change the color as needed */
  font-size: 30px;
  cursor: pointer;
  color: white;
  border-radius: 10px;
}

.close-button:hover {
  color: #ff0000; /* Change the hover color as needed */
}

</style>