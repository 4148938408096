import { createStore } from 'vuex';


const store =  createStore({
  state: {
    isUserLoggedIn: false,
    admin: {
        "name": "Mohan R",
        "email": "vanniyamatrimony@structers.com",
        "phone": "+91 9949100501",
    },
    user: {
        "id": "816f39f2-ff4a-46b5-8b94-526daaad51be",
        "name": "Lokesh Rajendran",
        "email": "mail.lokesh.vijaya@gmail.com",
        "phone": "6305239303"
    }
  },
  mutations: {
    addUser(state, userData) {
      state.isUserLoggedIn = true;
      Object.assign(state.user, userData);
    },
    logout(state) {
      state.isUserLoggedIn = false;
      state.user = {}
    }
  },
  actions: {
    // Your actions go here
  },
  modules: {
    // Your modules go here
  },
});

export default store;