<template>
    <section>
        <div class="str">
            <div class="hom-head" style="background-image: url('images/banner-new.jpeg');background-size: cover; background-position: left">
                <div class="container">
                    <div class="row">
                        <div class="hom-ban" style="background-color: rgba(0, 0, 0, 0.5);">
                            <div class="ban-tit" style="opacity: 100%;">
                                <span><i class="no1">#1</i> Matrimony for Vanniyars</span>
                                <h1>Find your<br><b style="color: orange">Right Match</b> here</h1>
                                <p>An Initiative for <b style="color: #FFDE59">Vanniyakula Kshatriya</b> Community</p>
                            </div>
                            <div v-if="!isUserLoggedIn" class="ban-search chosenini" style="width: 10%; background-color: transparent;" >
                                <a href="register" class="cta-dark" style="background-color: #607D8B; padding: 15px 35px; font-size: 18px;"><span>Register</span></a>
                            </div>
                            <div v-else class="ban-search chosenini" style="width: 10%; background-color: transparent;" >
                                <a href="user-onboard" class="cta-dark" style="background-color: #607D8B; padding: 15px 35px; font-size: 18px;"><span>Dashboard</span></a>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section>
        <div class="wedd-tline" style="color: black">
            <div class="container">
                <div class="row">
                    <div class="home-tit">
                        <p>Moments</p>
                        <h2><span>How it works</span></h2>
                        <span class="leaf1"></span>
                        <span class="tit-ani-"></span>
                    </div>
                    <div class="inn">
                        <ul>
                            <li>
                                <div class="tline-inn">
                                    <div class="tline-im animate animate__animated animate__slower"
                                        data-ani="animate__fadeInUp">
                                        <img src="images/icon/rings.png" alt="" loading="lazy">
                                    </div>
                                    <div class="tline-con animate animate__animated animate__slow"
                                        data-ani="animate__fadeInUp" style="color: black">
                                        <h5 style="color: black">Register</h5>
                                        <!-- <span>Timing: 7:00 PM</span> -->
                                        <p>Create an account with us by providing necessary information.</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="tline-inn tline-inn-reve">
                                    <div class="tline-con animate animate__animated animate__slower"
                                        data-ani="animate__fadeInUp">
                                        <h5>Find your Match</h5>
                                        <!-- <span>Timing: 7:00 PM</span> -->
                                        <p>We have verified profiles listed on our portal. Browse through the profiles and find your match.</p>
                                    </div>
                                    <div class="tline-im animate animate__animated animate__slow"
                                        data-ani="animate__fadeInUp">
                                        <img src="images/icon/wedding-2.png" alt="" loading="lazy">
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="tline-inn">
                                    <div class="tline-im animate animate__animated animate__slower"
                                        data-ani="animate__fadeInUp">
                                        <img src="images/icon/love-birds.png" alt="" loading="lazy">
                                    </div>
                                    <div class="tline-con animate animate__animated animate__slow"
                                        data-ani="animate__fadeInUp">
                                        <h5>Send Interest</h5>
                                        <!-- <span>Timing: 7:00 PM</span> -->
                                        <p>Send Interest to the profiles you like with your custom message. Your Interest will be notified to the profiles on the portal.</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="tline-inn tline-inn-reve">
                                    <div class="tline-con animate animate__animated animate__slower"
                                        data-ani="animate__fadeInUp">
                                        <h5>Get Profile Information</h5>
                                        <!-- <span>Timing: 7:00 PM</span> -->
                                        <p>Once your interest is accepted, contact details will be visible to both the parties on the portal</p>
                                    </div>
                                    <div class="tline-im animate animate__animated animate__slow"
                                        data-ani="animate__fadeInUp">
                                        <img src="images/icon/network.png" alt="" loading="lazy">
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="tline-inn">
                                    <div class="tline-im animate animate__animated animate__slower"
                                        data-ani="animate__fadeInUp">
                                        <img src="images/icon/chat.png" alt="" loading="lazy">
                                    </div>
                                    <div class="tline-con animate animate__animated animate__slow"
                                        data-ani="animate__fadeInUp">
                                        <h5>Start Meetups</h5>
                                        <!-- <span>Timing: 7:00 PM</span> -->
                                        <p>After obtaining contact details, you can initiate meetups to further acquaint yourselves with each other.</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="tline-inn tline-inn-reve">
                                    <div class="tline-con animate animate__animated animate__slower"
                                        data-ani="animate__fadeInUp">
                                        <h5>Get Married</h5>
                                        <!-- <span>Timing: 7:00 PM</span> -->
                                        <p>When everything falls into place as anticipated, it's that awaited moment we're eager for. Inform us once you tie the knot through our platform. We've prepared a special surprise for you.</p>
                                    </div>
                                    <div class="tline-im animate animate__animated animate__slow"
                                        data-ani="animate__fadeInUp">
                                        <img src="images/icon/wedding-couple.png" alt="" loading="lazy">
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section>
        <AboutUs></AboutUs>
    </section>
    <section>
        <HomeFooter></HomeFooter>
    </section>
    <!-- END -->
</template>

<script>

import { mapState } from 'vuex';
import AboutUs from '@/components/AboutUs.vue';
import HomeFooter from "@/components/Footer.vue";
import {  getCurrentUser} from 'aws-amplify/auth';

export default {
  name: 'HomeScreen',
  components: {
    AboutUs,
    HomeFooter
      },
  computed: {
    ...mapState(['isUserLoggedIn', 'user', 'admin'])
  },
  mounted() {
    getCurrentUser().then(currentUser => {
        console.log(currentUser)
        this.$store.commit("addUser", {
        "username": currentUser.username,
        "id": currentUser.userId,
        "email": currentUser?.signInDetails?.loginId
      })
    //   this.$router.push({"path": "/user-onboard"})
    }).catch((err) => console.log("User Not LoggedIn", err))
  },
//   props: {
//     msg: String
//   }
}

</script>