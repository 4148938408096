<template>
    <section>
        <div class="login pro-edit-update">
            <div class="container">
                <div class="row">
                    <div class="inn">
                        <div class="rhs">
                                <div class="form-login">
                                    <form>
                                        <!--PROFILE BIO-->
                                        <div class="edit-pro-parti">
                                            <div class="form-tit">
                                                <h4>step 3/3 - Preferences</h4>
                                                <h1>Set your preferences</h1>
                                                <h4>You can always change your preferences in settings</h4>
                                            </div>
                                            <div class="form-group">
                                                <label class="lb">I am Looking for:</label>
                                                <select class="form-select chosen-select" data-placeholder="Select the gender">
                                                        <option></option>
                                                        <option>Male</option>
                                                        <option>Female</option>
                                                </select>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">With Minimum Age (optional):</label>
                                                    <input type="number" class="form-control" id="min_age"
                                                    name="min_age">
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">With Minimum Height (optional):</label>
                                                    <input type="number" class="form-control" id="min_height"
                                                    name="min_height" >
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">With Minimum Salary (optional):</label>
                                                    <input type="number" class="form-control" id="min_salary"
                                                   name="min_salary">
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">Job Locations (optional):</label>
                                                    <input type="text" class="form-control" id="job_city"
                                                     name="job_city" >
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">Nakshathram (optional):</label>
                                                    <input type="text" class="form-control" >
                                                </div><div class="col-md-6 form-group">
                                                    <label class="lb">Rasi (optional):</label>
                                                    <select class="form-select chosen-select" data-placeholder="Select your Rasi">
                                                        <option></option>
                                                        <option>Aries / మేషం / மேஷம்</option>
                                                        <option>Taurus / వృషభం / ரிஷபம்</option>
                                                        <option>Gemini / మిథునం / மிதுனம்</option>
                                                        <option>Cancer / కర్కాటకం / கடகம்</option>
                                                        <option>Leo / సింహం / சிம்மம்</option>
                                                        <option>Virgo / కన్యా / கன்னி</option>
                                                        <option>Libra / తులారాశి / துலாம்</option>
                                                        <option>Scorpio / వృశ్చికం / விருச்சிகம்</option>
                                                        <option>Sagittarius / ధనుస్సు / தனுசு</option>
                                                        <option>Capricorn / మకరరాశి / மகரம்</option>
                                                        <option>Aquarius / కుంభరాశి / கும்பம்</option>
                                                        <option>Pisces / మీనరాశి / மீனம்</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">Gothram (optional):</label>
                                                    <input type="text" class="form-control">
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">Caste (optional):</label>
                                                    <input type="text" class="form-control" value="Vanniya Kula Kshatriya" disabled>
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <label class="lb">Sub Caste (optional):</label>
                                                    <input type="text" class="form-control">
                                                </div>
                                            </div>
                                        </div>
                                        <!--END PROFILE BIO-->
                                        <!--PROFILE BIO-->
                                        
                                        <!--END PROFILE BIO-->
                                        <!--PROFILE BIO-->
                                        <div class="edit-pro-parti">
                                            <div class="form-tit">
                                                <h4>Terms and Conditions</h4>
                                                <h1>Set your private settings, terms and conditions</h1>
                                                <h4>You can always change your notification choices in settings</h4>
                                            </div>
                                            <div class="form-group form-check">
                                            <label class="form-check-label">
                                                <input  v-model="remember_me" class="form-check-input" type="checkbox" name="agree" required> Email/SMS me new profile matches
                                            </label>
                                            </div>
                                            <div class="form-group form-check">
                                            <label class="form-check-label">
                                                <input  v-model="remember_me" class="form-check-input" type="checkbox" name="agree" required> Email/SMS me new profile interests
                                            </label>
                                            </div>
                                            <div class="form-group form-check">
                                            <label class="form-check-label">
                                                <input  v-model="remember_me" class="form-check-input" type="checkbox" name="agree" required> I Accept Vanniyar Matrimony Terms and Conditions
                                            </label>
                                            </div>
                                            <div class="form-group form-check">
                                            <label class="form-check-label">
                                                <input  v-model="remember_me" class="form-check-input" type="checkbox" name="agree" required> I Agree for Vanniyar Matrimony agents to call me to verify my profile
                                            </label>
                                            </div>
                                        </div>
                                        
                                        <!--END PROFILE BIO-->
                                        <button type="submit" class="btn btn-primary">Complete my Profile</button>
                                    </form>
                                </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>

// import { mapState } from 'vuex';
// import { signIn as AmplifySignIn} from 'aws-amplify/auth';

export default {
  name: 'UserPreferences',
//   data() {
//     return {
//       email: '',
//       code: '',
//     };
//   },
//   computed: {
//     ...mapState(['isUserLoggedIn', 'user', 'admin'])
//   },
//   methods: {
//     verifyCode() {
//         console.log(this.email, this.code)
//         // AmplifySignIn({
//         //     username: this.email, 
//         //     password: this.password
//         // }).then(res => console.log(res)).catch(err => console.log(err))
//     }
//   }
//   props: {
//     msg: String
//   }
}
</script>
