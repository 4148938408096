<template>
     <section>
        <div class="login pg-faq">
            <div class="container">
                <div class="row">
                    <div class="inn ab-faq-lhs">
                        <div class="form-tit">
                            <h4>FAQ</h4>
                            <h1>Frequently asked questions</h1>
                        </div>
                        <div id="accordion">
                            <div class="card">
                                <div class="card-header">
                                    <a class="card-link" data-toggle="collapse" href="#collapse10">
                                        How to register on vanniya matrimony?
                                    </a>
                                </div>
                                <div id="collapse1" class="collapse  show" data-parent="#accordion">
                                    <div class="card-body">
                                        <p><a style="color:black" href="www.vanniyamatrimony.com/register">Click Here</a> to create an account and enter all the required details to get started</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header">
                                    <a class=" card-link" data-toggle="collapse" href="#collapse2">
                                        Is registration free?
                                    </a>
                                </div>
                                <div id="collapse2" class="collapse show" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>Registration is free for first three months. Later you need to buy relevant package as per your needs</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header">
                                    <a class=" card-link" data-toggle="collapse" href="#collapse3">
                                        Can I search profiles without registering?
                                    </a>
                                </div>
                                <div id="collapse3" class="collapse  show" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>No, You cannot search profiles without registration. Once registered our team will check the authenticity of your profile only then you will be allowed to search profiles.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header">
                                    <a class=" card-link" data-toggle="collapse" href="#collapse4">
                                        How safe is my data?
                                    </a>
                                </div>
                                <div id="collapse4" class="collapse  show" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>Your data is our responsibility. Your data is encrypted at all the storage and transit points from our end. Even our Team cannot access your complete data without extra authentication.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header">
                                    <a class=" card-link" data-toggle="collapse" href="#collapse5">
                                        Can I Delete my Account?
                                    </a>
                                </div>
                                <div id="collapse5" class="collapse show" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>Yes, you own the complete authority over your data. You can delete your data from the portal Or call our support team to delete it</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header">
                                    <a class=" card-link" data-toggle="collapse" href="#collapse6">
                                        How can I report any fraud?
                                    </a>
                                </div>
                                <div id="collapse6" class="collapse show" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>You can call us on our contact numner +91 9949100501 or write to us at vanniyamatrimony@structers.com</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header">
                                    <a class=" card-link" data-toggle="collapse" href="#collapse6">
                                        What is Structers?
                                    </a>
                                </div>
                                <div id="collapse6" class="collapse show" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>Structers Technologies Private Limited, is the software company who created this product</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
  name: 'FAQ',
}
</script>