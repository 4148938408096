<template>
    <section>
        <div class="login">
            <div class="container">
                <div class="row">

                    <div class="inn">
                        <div class="lhs">
                            <div class="tit">
                                <h2>Now <b>Find <br> your life partner</b> Easy and fast.</h2>
                            </div>
                            <div class="im">
                                <img src="images/login-couple.png" alt="">
                            </div>
                            <div class="log-bg">&nbsp;</div>
                        </div>
                        <div class="rhs">
                            <div>
                                <div class="form-tit">
                                    <h4>Kudos !!</h4>
                                    <!-- <h1>You are registered with Us</h1> -->
                                    <h1>You three months premium plan will be activated</h1>
                                </div>
                                <div class="form-login">
                                    <form @submit.prevent="proceedToBio">
                                        
                                        <div class="form-group">
                                            <h1>The site will be live on 30th March 2024</h1>
                                            <p>Our team will get in touch with you once we are live</p>
                                        </div>
                                        <div style="margin:10px;padding:10px;margin-top: 30px;">
                                            <h5 style="color:#333333">Things that will be shared on Portal</h5>
                                            <ul class="styled-list">
                                                <li>Your Name</li>
                                                <li>Your Photos</li>
                                                <li>Age</li>
                                                <li>Parents details</li>
                                                <li>Profession (without salary info)</li>
                                            </ul>
                                        </div>
                                        <div>
                                            <h6 style="color:red">All other information will not be shared on Portal without your consent</h6>
                                        </div>

                                        <!-- <div class="form-group">
                                            <label class="lb">code:</label>
                                            <input v-model="code" type="number" class="form-control" id="pwd"
                                                placeholder="Code" name="pswd" required>
                                        </div> -->
                                        <!-- <button type="submit" class="btn btn-primary">Start Bio Setup</button> -->
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>

import { mapState } from 'vuex';
// import { signIn as AmplifySignIn} from 'aws-amplify/auth';

export default {
  name: 'BioWelcomeScreen',
//   data() {
//     return {
//       email: '',
//       code: '',
//     };
//   },
  computed: {
    ...mapState(['isUserLoggedIn', 'user', 'admin'])
  },
  methods: {
    proceedToBio() {
        // console.log(this.email, this.code)
        // AmplifySignIn({
        //     username: this.email, 
        //     password: this.password
        // }).then(res => console.log(res)).catch(err => console.log(err))
    }
  }
//   props: {
//     msg: String
//   }
}
</script>
<style scoped>
.styled-list {
  list-style-type: none;
  padding-left: 20px; /* Adjust the padding as needed */
}

.styled-list li::before {
  content: '•';
  color: green;
  display: inline-block;
  width: 1em; /* Adjust the width to control spacing between bullet and text */
  margin-left: -1em; /* Align the bullet properly with the padding */
}
</style>
