<template>
  <router-view></router-view>
</template>

<script>

// import HomeLayout from "./layouts/HomeLayout.vue"

export default {
  name: 'App',
  components: {
    // HomeLayout
  }
}
</script>

<style>
</style>
