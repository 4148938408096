<template>
    <section class="wed-hom-footer">
        <div class="container">
            <div class="row foot-supp">
                <h2><span> support:</span> + 91 9949100501 &nbsp;&nbsp;|&nbsp;&nbsp; <span>Email:</span>vanniyamatrimony@structers.com</h2>
            </div>
            <div class="row wed-foot-link wed-foot-link-1">
                <div class="col-md-4">
                    <h4>Get In Touch</h4>
                    <p>Address: 15-1262/1, Vinayakapuram, Chittoor</p>
                    <p>Phone: <a href="tel:+917904462944">+91 9949100501</a></p>
                    <p>Email: <a href="mailto:info@example.com">vanniyamatrimony@structers.com</a></p>
                </div>
                <div class="col-md-4">
                    <h4>HELP &amp; SUPPORT</h4>
                    <ul>
                        <li><a href="about-us">About company</a>
                        </li>
                        <li><a href="faq">FAQs</a>
                        </li>
                    </ul>
                </div>
                <!-- <div class="col-md-4 fot-soc">
                    <h4>SOCIAL MEDIA</h4>
                    <ul>
                        <li><a href="#!"><img src="images/social/1.png" alt="" loading="lazy"></a></li>
                        <li><a href="#!"><img src="images/social/2.png" alt="" loading="lazy"></a></li>
                        <li><a href="#!"><img src="images/social/3.png" alt="" loading="lazy"></a></li>
                        <li><a href="#!"><img src="images/social/5.png" alt="" loading="lazy"></a></li>
                    </ul>
                </div> -->
            </div>
            <div class="row foot-count">
                <p>vanniyamatrimony - Connecting Hearts, Creating Futures <a
                        href="register" class="btn btn-primary btn-sm">Join us today !</a></p>
            </div>
        </div>
    </section>
    <!-- END -->

    <!-- COPYRIGHTS -->
    <section>
        <div class="cr">
            <div class="container">
                <div class="row">
                    <p>Copyright © <span id="cry">2024</span> <a href="https://www.structers.com">structers.com</a> All
                        rights reserved.</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
  name: 'HomeFooter',
}
</script>