<template>
    <div>
        <AppHeader></AppHeader>
        <router-view></router-view>
    </div>
</template>

<script>
    import AppHeader from '@/components/Header.vue';
    
    export default {
      name: "HomeLayout",
      components: {
        AppHeader,
      },
};
</script>